import React from "react";
import style from "./cart.module.scss";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddonNotification, AlertBs } from "../common";
import CartTable from "./CartTable";
import Nophoto from "../../../assets/images/cart/cart.jpg";

const CartContent = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  const currency = useSelector((state) => state.curr);

  const viewPackage = (id) => {
    history.push(`/package_details/${id}`);
  };

  return (
    <div className={style.container}>
      <AlertBs
        varient={props.alert.varient}
        show={props.alert.show}
        message={props.alert.message}
        close={props.closeAlert}
      />
      {/* <AddonNotification /> */}
      {props.cart && props.cart.length > 0 && <CartTable cart={props.cart} />}
      <Row>
        {props.data.map((item, index) => (
          <Col key={index} md={4} lg={4}>
            <Card>
              <Card.Body className={`${style.panel}`}>
                <div className={`text-center`}>
                  {item.image ? (
                    <img
                      src={item.image}
                      className={style.cartItemImage}
                      alt="No Image"
                    />
                  ) : (
                    <img
                      src={Nophoto}
                      className={style.cartItemImage}
                      alt="No Image"
                    />
                  )}
                </div>
                <div className={style.itemTitle}>
                  <h4>{item.name}</h4>
                </div>
                <div className={style.textCenter}>
                  <span className={style.categoryTxt}>{item.category}</span>
                </div>
                <div className={style.textCenter}>
                  <small className={style.price}>
                    {currency.currentCurr}{" "}
                    {(item.amount * currency.value).toFixed(currency.precesion)}
                  </small>
                </div>
              </Card.Body>
              <Card.Footer className={style.noPadder}>
                <Row className={style.noGutter}>
                  <Col md={6} lg={6} style={{ padding: 0 }}>
                    <div className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_wrapper} text-center`
                          : `${style.wrapper} ${style.br} text-center`
                      }`}>
                      <Button
                        variant="info"
                        className={`${style.btnAddon} ${style.btnAddonInfo}`}
                        onClick={() => {
                          props.addToCart(item.id, item.name);
                        }}
                      >
                        <i className="fa fa-shopping-cart"></i>
                        {t("Button.addToCart")}
                      </Button>
                    </div>
                  </Col>

                  <Col md={6} lg={6} style={{ padding: 0 }}>
                    <div className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_wrapper} text-center`
                          : `${style.wrapper} ${style.br} text-center`
                      }`}>
                      <Button
                        onClick={() => viewPackage(item.id)}
                        variant="success"
                        className={`${style.btnAddon} ${style.btnAddonSuccess}`}
                      >
                        <i className="fa fa-eye"></i>
                        {t("Button.moreDetails")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CartContent;
