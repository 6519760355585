import React from "react";
import style from "./auth.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const LoginButton = ({ submit, ...props }) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  return (
    <div className={style.loginButton}>
      <button
        className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_loginFormBtn}`
              : `${style.loginFormBtn}`
          }`}
        onClick={submit}
        disabled={props.disabled || props.loader}
        style={{backgroundColor:"pink"}}
      >
        {" "}
        {props.loader ? (
          <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
        ) : (
          ""
        )}{" "}
        {t("Login.LoginBtn")}
      </button>
    </div>
  );
};

export default LoginButton;
