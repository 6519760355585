import React from 'react';
import style from './support.module.scss';
import { Row, Col, Button } from 'react-bootstrap';
import AlertBs from '../common/AlertBs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'

const TimeLineContent = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const backButtonClick = () => {
        history.goBack()

    }

    let time_lines = props.time_lines.map((timeline, key) => {
        return (

            <div key={key} className={`${style.demo_card} ${key % 2 === 0 ? style.democardStep1 : style.democardStep2}`}>
                <div className={style.head}>
                    <div className={style.number_box}>
                        <span>{key+1}</span>
                    </div>
                    <h4>{timeline.activity}</h4>
                </div>
                <div className={style.body} >
                    <p className="text-center">{timeline.message}</p>
                    <p className="text-center">{timeline.date}</p>
                    <p>{t('timeLine.doneBy')} : {timeline.done_by}</p>
                </div>
            </div>
        );
    });

    return (
        <div className={style.container}>
            <div style={{ textAlign: 'right' }}>
                <Button
                    className={style.btnGrop}
                    variant="primary"
                    onClick={backButtonClick}
                >
                    <i className="fa fa-backward"></i>
                    {t('Button.go_to_support_center')}
                </Button>
            </div>
            <Row>
                <Col>
                    <AlertBs
                        {...props.alert}
                        close={props.closeAlert}
                    />
                    <legend><span className="fieldset-legend">{t('timeLine.ticket')} - {props.ticket_id}</span></legend>

                    <div className={style.timeline}>
                        <div className={style.demo_card_wrapper}>
                            {time_lines}
                        </div>
                        <Link to={`/support/ticket-details/${props.ticket_id}`} className="btn-link h4 text-primary" title="timeline">
                            <Button variant="info" className="mr-2">{t('timeLine.reply')}</Button>
                        </Link>
                    </div>

                </Col>
            </Row>
        </div>
    );
}

export default TimeLineContent;