import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import style from "../style/App.module.scss";
import { Language } from "../components/common/";
import Brand from "../components/auth/Brand";
import { useSelector } from "react-redux";
import profileImg from "../../assets/images/auth/profile-img.png";
import imageUrl from "../../assets/images/login-bg.jpeg";
import logo from "../../assets/images/super-star-logo.png";
// import { motion } from 'framer-motion/dist/framer-motion'

function AuthLayout({ children }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  useEffect(() => {
    if (currentTheme !== "theme-dark") {
      document.body.style.setProperty("background", "#f8f8fb", "important");
    }
    // document.body.style.setProperty("p", "12px", "important");
    return () => {
      document.body.style.removeProperty("background");
    };
  }, []);
  return (
    <div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_app} pt-5`
          : `${style.app} pt-5`
      }`}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "100% 100%", // or "contain" based on your preference
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      {console.log("AuthLayout")}
      <Container
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_loginContainer}`
            : `${style.loginContainer}`
        }`}
      >
        <div className="row justify-content-center">
          <Brand />
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_style_bgPrimary}`
                : `${style.bgPrimary}`
            }`}
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "cover", // or "contain" based on your preference
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              width: "100%",
              padding: "5px 0",
              marginLeft: "-1px",
            }}
          >
            <div className="row align-items-center">
              <div className="col-7">
                <div className="text-primary p-4"  >
                  <h5 style={{color:"white"}} >
                   <strong>Welcome Back !</strong>
                  </h5>
                  <p
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty("fontSize", "12px", "important");
                      }
                    }}
                    style={
                      currentTheme == "theme-dark"
                        ? { color: "white", fontSize: "12px !important" }
                        : { color: "white", fontSize: "12px !important" }
                    }
                  >
                    Sign in to continue
                  </p>
                </div>
              </div>
              <div className="col-5">
                <img src={logo} alt="" className="img-fluid" style={{width:"100px",float:"right",marginRight:"45px"}}/>
              </div>
            </div>
          </div>
        </div>
        <div className={`${style.bgLogin}`}>{children}</div>
        {/* <Brand /> */}
        {/* <Language classname="mb-4 w-100" /> */}
        {/* <div class="bg-primary bg-soft">
        <div class="row">
         <div class="col-7">
         <div class="text-primary p-4">
          <h5 class="text-primary">Welcome Back !</h5>
          <p>Sign in to continue</p>
           </div>
                                </div>
                                <div class="col-5 align-self-end">
                                    <img src="assets/images/profile-img.png" alt="" class="img-fluid"/>
                                </div>
                            </div>
                        </div> */}
      </Container>
      {/* <div
        className={`${currentTheme == "theme-dark"
            ? `${style.dark_mode_lg_login_btn_btm_1}`
            : `${style.lg_login_btn_btm_1}`
          }`}
      >
        2023 © Company Name -{" "}
        <a href="https://ioss.in/">
          {" "}
          Developed by Infinite Open Source Solutions LLP™
        </a>
      </div> */}
    </div>
  );
}

export default AuthLayout;
