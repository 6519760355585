import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./payment.module.scss";
import Payments from "../../../service/common/PaymentApi";
import { FormInputControl } from "../common";
import { useSelector } from "react-redux";

function Ewallet(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new Payments();
  const { t } = useTranslation();
  const [state, setState] = useState({
    username: "",
    transpassword: "",
    FormError: {
      username: "",
      transpassword: "",
      bala: false,
    },
    isvalid: false,
    loader: false,
  });

  const submitHandler = (e) => {
    window.location.pathname.includes("/register") ||
    window.location.pathname.includes("/rep_register")
      ? props.submit(e, "", "payment")
      : props.submit(e, "");
      setState({
        ...state,
        username:"",
        transpassword:""
      })
  };

  //change handler
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      FormError: {
        ...prev.FormError,
        [name]: "",
      },
      isvalid: false,
    }));
  };

  //check the ewallet balance
  const checkAvailable = () => {
    let valid = true;
    if (!state.username) {
      valid = false;
      setState((prev) => ({
        ...prev,
        FormError: {
          ...prev.FormError,
          username: t("validation.required", { field: "userName" }),
        },
      }));
    }
    if (!state.transpassword) {
      valid = false;
      setState((prev) => ({
        ...prev,
        FormError: {
          ...prev.FormError,
          transpassword: t("validation.required", {
            field: "transactionPassword",
          }),
        },
      }));
    }
    if (valid) {
      let userData = {
        user_name: state.username,
        ewallet: state.transpassword,
        product_id: props.product,
        payment_type: props.payment_type,
      };
      service.ewalletavailability(userData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            isvalid: true,
            FormError: {
              transpassword: "validation.validEwalletDetails",
              username: "",
            },
          }));
          props.EwalletData(state.username, state.transpassword);
        } else {
          if (res.error.code === 1039 || res.error.code === 1003) {
            setState((prev) => ({
              ...prev,
              FormError: {
                ...prev.FormError,
                transpassword: t("validation.invalidTransactionDetails"),
              },
            }));
          } else if (res.error.code === 1014) {
            setState((prev) => ({
              ...prev,
              FormError: {
                ...prev.FormError,
                bala: true,
              },
            }));
          }
        }
      });
    }
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_MianContent}`
          : `${style.MianContent}`
      }`}
    >
      <Form
        onSubmit={(e) => {
          setState((prev) => ({
            ...prev,
            loader: true,
          }));
          submitHandler(e);
        }}
      >
        <FormInputControl
          label={t("profile.userName")}
          required={true}
          error={{
            error: state.FormError.username,
          }}
          textIcon={true}
          inputProps={{
            type: "text",
            name: "username",
            onChange: changeHandler,
            value:state.username,
          }}
        />
        <FormInputControl
          label={t("profile.transactionPassword")}
          required={true}
          error={{
            error: state.FormError.transpassword,
          }}
          textIcon={true}
          textSuccess={state.isvalid}
          inputProps={{
            type: "password",
            name: "transpassword",
            onChange: changeHandler,
            value:state.transpassword,
          }}
        />
        {state.FormError.bala === true && (
          <Form.Text className={style.FormError}>
            {t("validation.insufficientBalance")}
          </Form.Text>
        )}
        <Form.Group className={style.Buttons}>
          <Button onClick={checkAvailable} className={style.checkAvailable}>
            {t("Button.checkAvailability")}
          </Button>
          {props.prevButtonClick && (
            <Button
              className={style.PrevButton}
              onClick={props.prevButtonClick}
            >
              {t("profile.prev")}
            </Button>
          )}
          <Button
            className={style.NextButton}
            disabled={!state.isvalid ? true : props.loader ? true : false}
            type="submit"
          >
            {props.loader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              ""
            )}
            {t("Button.finish")}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Ewallet;
