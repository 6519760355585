// export default function CurrencyDecimalFormat(amount, precision) {
//   if (amount > 10000) {
//     var si = [
//       { value: 1, symbol: "" },
//       { value: 1e3, symbol: "K" },
//       { value: 1e6, symbol: "M" },
//       { value: 1e9, symbol: "G" },
//       { value: 1e12, symbol: "T" },
//       { value: 1e15, symbol: "P" },
//       { value: 1e18, symbol: "E" },
//     ];
//     var i;
//     for (i = si.length - 1; i > 0; i--) {
//       if (amount >= si[i].value) {
//         break;
//       }
//     }
//     return numberWithCommas((amount / si[i].value).toFixed(2)) + si[i].symbol;
//   } else {
//     return numberWithCommas(amount.toFixed(precision));
//   }
// }

// function numberWithCommas(x) {
//   return new Intl.NumberFormat().format(parseFloat(x).toFixed(2));
// }

export default function CurrencyDecimalFormat(amount, precision) {
  if (amount > 10000) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (amount >= si[i].value) {
        break;
      }
    }
    return numberWithCommas((amount / si[i].value).toFixed(2)) + si[i].symbol;
  } else {
    return numberWithCommas(amount.toFixed(2));
  }
}

function numberWithCommas(x) {
  return parseFloat(x).toFixed(2);
}
